import React from 'react';

const CommentSection = ({ comments }) => {
  if (!comments || comments.length === 0) return null;

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4 text-white font-heading">Comments</h2>
      {comments.map((comment) => (
        <div key={comment.id} className="bg-gray-700 p-4 rounded-md shadow mb-4">
          <div className="flex justify-between items-center mb-2">
            <p className="font-semibold text-yellow-400">{comment.author}</p>
            <p className="text-sm text-gray-400">{comment.date}</p>
          </div>
          <p className="text-gray-300">{comment.text}</p>
        </div>
      ))}
    </div>
  );
};

export default CommentSection;