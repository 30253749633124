import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const checkUserRole = async (userId) => {
  const userDoc = await getDoc(doc(db, "users", userId));
  if (userDoc.exists()) {
    return {
      isAdmin: userDoc.data().isAdmin || false,
      group: userDoc.data().group || 'standard'
    };
  }
  return { isAdmin: false, group: 'standard' };
};