// src/components/AuthModal.js
import React, { useState } from 'react';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import useAuth from '../../hooks/useAuth';

const AuthModal = ({ isOpen, onClose, isFromSubmitSong = false }) => {
  const [isRegistering, setIsRegistering] = useState(true);
  const { error, handleRegister, handleLogin } = useAuth(onClose);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-8 max-w-md w-full">
        {isFromSubmitSong && (
          <div className="mb-4 text-yellow-400 text-center font-heading">
            Not logged in. Register or log in to submit a song.
          </div>
        )}
        <div className="flex mb-4">
          <button
            className={`flex-1 p-2 ${isRegistering ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'} rounded-tl-lg rounded-tr-lg font-heading`}
            onClick={() => setIsRegistering(true)}
          >
            Register
          </button>
          <button
            className={`flex-1 p-2 ${!isRegistering ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'} rounded-tl-lg rounded-tr-lg font-heading`}
            onClick={() => setIsRegistering(false)}
          >
            Login
          </button>
        </div>
        {isRegistering ? (
          <RegisterForm onSubmit={handleRegister} />
        ) : (
          <LoginForm onSubmit={handleLogin} />
        )}
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <button onClick={onClose} className="w-full p-2 text-white bg-gray-600 rounded hover:bg-gray-700">
          Close
        </button>
      </div>
    </div>
  );
};

export default AuthModal;