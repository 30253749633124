import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { r2Client, BUCKET_NAME } from '../r2Config';
import { doc, updateDoc, increment } from "firebase/firestore";
import { db } from '../firebase';

export const downloadSong = async (song) => {
  try {
    console.log('Full song object:', song);
    console.log('Song file URL:', song.songFileUrl);

    // Extract the key from the URL
    const key = `songs/${song.songFileUrl.split('/').pop()}`;
    console.log('Extracted key:', key);

    const command = new GetObjectCommand({
      Bucket: BUCKET_NAME,
      Key: key,
    });

    console.log('Bucket name:', BUCKET_NAME);

    const signedUrl = await getSignedUrl(r2Client, command, { expiresIn: 3600 });
    console.log('Generated signed URL:', signedUrl);

    // Use fetch to get the file
    const response = await fetch(signedUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${song.title}.mp3`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up
    URL.revokeObjectURL(url);

    // Update download count in Firestore
    const songDocRef = doc(db, 'songs', song.id);
    await updateDoc(songDocRef, {
      downloads: increment(1)
    });

    console.log(`Song ${song.id} downloaded successfully`);
  } catch (error) {
    console.error("Error downloading song: ", error);
    throw error;
  }
};