import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Adjust this import path as needed
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

const UserProfileForm = ({ isOpen, onClose, anchorRef, openAuthModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeDoc = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setUsername(docSnapshot.data().username || 'User');
          }
        });
        return () => unsubscribeDoc();
      } else {
        setUsername('');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError(null);
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      onClose();
      navigate('/'); // Redirect to the main page after logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError("Bitte geben Sie Ihre E-Mail-Adresse ein.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet. Bitte überprüfen Sie Ihren Posteingang.");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditProfile = () => {
    navigate('/profile');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div 
      className="absolute right-0 mt-2 w-64 bg-gray-800 rounded-md overflow-hidden shadow-xl z-10"
      style={{
        top: anchorRef.current ? anchorRef.current.offsetHeight : 0,
      }}
    >
      {auth.currentUser ? (
        // User is logged in
        <div className="p-4">
          <div className="text-sm font-medium text-white mb-2 font-heading">{username}</div>
          <button onClick={handleEditProfile} className="block w-full py-2 px-4 mb-2 text-sm text-center text-white bg-blue-500 rounded hover:bg-blue-600">
            Profil bearbeiten
          </button>
          <button onClick={handleLogout} className="block w-full py-2 px-4 text-sm text-center text-white bg-red-500 rounded hover:bg-red-600">
            Abmelden
          </button>
        </div>
      ) : (
        // User is not logged in
        <div className="p-4">
          <h3 className="text-lg font-medium text-white mb-3 font-heading">Login</h3>
          <form onSubmit={handleLogin}>
            <input 
              type="email" 
              placeholder="E-Mail" 
              className="w-full p-2 mb-2 text-sm text-white bg-gray-700 rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input 
              type="password" 
              placeholder="Passwort" 
              className="w-full p-2 mb-2 text-sm text-white bg-gray-700 rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit" className="w-full p-2 mb-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600">
              Anmelden
            </button>
          </form>
          {error && <p className="mb-2 text-xs text-red-500">{error}</p>}
          <div className="flex justify-between text-xs">
            <button onClick={openAuthModal} className="text-blue-400 hover:underline">
              Registrieren
            </button>
            <button onClick={handlePasswordReset} className="text-blue-400 hover:underline">
              Passwort vergessen?
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileForm;