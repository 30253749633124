import { useState, useEffect } from 'react';
import { getSongCount } from '../utils/statsManagement';

const CACHE_KEY = 'songCount';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const FALLBACK_COUNT = 1000; // Fallback count when unable to fetch

export const useSongCount = () => {
  const [songCount, setSongCount] = useState(() => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { count, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return count;
      }
    }
    return null;
  });

  useEffect(() => {
    const fetchSongCount = async () => {
      try {
        console.log('Attempting to fetch song count from Firebase');
        const count = await getSongCount();
        console.log('Song count fetched:', count);
        setSongCount(count);
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          count,
          timestamp: Date.now()
        }));
      } catch (error) {
        console.error("Error fetching song count:", error);
        // If there's an error, use the cached count if available, otherwise use fallback
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { count } = JSON.parse(cached);
          setSongCount(count);
        } else {
          setSongCount(FALLBACK_COUNT);
        }
      }
    };

    // Only fetch if we don't have a count or if the cached count is older than 24 hours
    if (songCount === null) {
      fetchSongCount();
    } else {
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        const { timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp >= CACHE_DURATION) {
          fetchSongCount();
        }
      }
    }
  }, [songCount]);

  return songCount || FALLBACK_COUNT;
};