import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';

export const addGenre = async (genreName) => {
  console.log('Attempting to add new genre:', genreName);
  try {
    const genresCollection = collection(db, 'genres');
    const q = query(genresCollection, where("name", "==", genreName));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      console.log('Genre already exists:', genreName);
      return querySnapshot.docs[0].id;
    }

    const genreRef = await addDoc(genresCollection, { name: genreName });
    console.log('New genre added successfully. Genre ID:', genreRef.id);
    return genreRef.id;
  } catch (error) {
    console.error('Error in addGenre:', error);
    throw error;
  }
};

export const getGenres = async () => {
  console.log('Fetching all genres');
  try {
    const genresCollection = collection(db, 'genres');
    const querySnapshot = await getDocs(genresCollection);
    const genres = querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name
    }));
    console.log('Genres fetched successfully:', genres);
    return genres;
  } catch (error) {
    console.error('Error in getGenres:', error);
    throw error;
  }
};

export const handleGenreSelection = async (selectedGenre) => {
  console.log('Handling genre selection:', selectedGenre);
  try {
    if (selectedGenre.__isNew__) {
      const newGenreId = await addGenre(selectedGenre.label);
      console.log('New genre added with ID:', newGenreId);
      return {
        id: newGenreId,
        name: selectedGenre.label
      };
    } else {
      return {
        id: selectedGenre.value,
        name: selectedGenre.label
      };
    }
  } catch (error) {
    console.error('Error in handleGenreSelection:', error);
    throw error;
  }
};