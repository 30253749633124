import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

export const getUsernameByUid = async (uid) => {
  console.log('Fetching username for UID:', uid);
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      const username = userDoc.data().username;
      console.log('Username found:', username);
      return username;
    }
    console.log('No user document found for UID:', uid);
    return null;
  } catch (error) {
    console.error('Error in getUsernameByUid:', error);
    throw error;
  }
};