import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../@/components/ui/table';
import { PlayCircle, Pause, Download, ChevronUp, ChevronDown } from 'lucide-react';
import { useAudio } from '../../contexts/AudioContext';
import { downloadSong } from '../../utils/downloadSong';

const SongTable = ({ songs, currentlyPlaying, handleSongClick }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'ascending' });
  const { playSong, pauseSong } = useAudio();

  const sortedSongs = [...songs].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
    }
    return null;
  };

  const handlePlayPause = (e, song) => {
    e.stopPropagation();
    if (currentlyPlaying && currentlyPlaying.id === song.id) {
      pauseSong();
    } else {
      playSong(song).catch(error => {
        console.error("Error playing song:", error);
      });
    }
  };

  const handleDownloadSong = async (e, song) => {
    e.stopPropagation();
    try {
      await downloadSong(song);
    } catch (error) {
      console.error("Error downloading song:", error);
    }
  };

  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="text-gray-300 cursor-pointer flex items-center" onClick={() => handleSort('title')}>
              Title {getSortIcon('title')}
            </TableHead>
            <TableHead className="hidden sm:table-cell text-gray-300 cursor-pointer items-center" onClick={() => handleSort('artist')}>
              Artist {getSortIcon('artist')}
            </TableHead>
            <TableHead className="hidden sm:table-cell text-gray-300 cursor-pointer items-center" onClick={() => handleSort('genreName')}>
              Genre {getSortIcon('genreName')}
            </TableHead>
            <TableHead className="text-gray-300 cursor-pointer flex items-center" onClick={() => handleSort('rating')}>
              Rating {getSortIcon('rating')}
            </TableHead>
            <TableHead className="hidden sm:table-cell text-gray-300 cursor-pointer items-center" onClick={() => handleSort('uploadedAt')}>
              Upload Date {getSortIcon('uploadedAt')}
            </TableHead>
            <TableHead className="text-gray-300 cursor-pointer flex items-center" onClick={() => handleSort('downloads')}>
              Downloads {getSortIcon('downloads')}
            </TableHead>
            <TableHead className="text-gray-300">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedSongs.map((song) => (
            <TableRow key={song.id} onClick={() => handleSongClick(song)}>
              <TableCell className="font-medium">
                <div className="flex items-center gap-2">
                  <img 
                    src={song.coverImageUrl || "https://via.placeholder.com/48"} 
                    alt="Song cover" 
                    className="w-12 h-12 rounded-md object-cover"
                  />
                  <div>
                    <div className="text-yellow-400">{song.title}</div>
                    <div className="text-sm text-gray-300">{song.artist}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="hidden sm:table-cell text-gray-300">{song.artist}</TableCell>
              <TableCell className="hidden sm:table-cell text-gray-300">{song.genreName || 'No Genre'}</TableCell>
              <TableCell>
                <div className="flex items-center">
                  <span className="text-green-500 mr-1">↑{song.upvotes || 0}</span>
                  <span className="text-red-500">↓{song.downvotes || 0}</span>
                </div>
              </TableCell>
              <TableCell className="hidden sm:table-cell text-gray-300">
                {song.uploadedAt ? new Date(song.uploadedAt.seconds * 1000).toLocaleDateString() : 'N/A'}
              </TableCell>
              <TableCell className="text-gray-300">{song.downloads || 0}</TableCell>
              <TableCell>
                <div className="flex gap-2">
                  <button 
                    className={`p-2 rounded-full ${currentlyPlaying && currentlyPlaying.id === song.id ? 'bg-red-500' : 'bg-yellow-500'} text-black`}
                    onClick={(e) => handlePlayPause(e, song)}
                  >
                    {currentlyPlaying && currentlyPlaying.id === song.id ? <Pause size={16} /> : <PlayCircle size={16} />}
                  </button>
                  <button 
                    className="p-2 bg-green-500 text-white rounded-full"
                    onClick={(e) => handleDownloadSong(e, song)}
                  >
                    <Download size={16} />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default SongTable;