// SidebarContentSingleSongView.js
import React from 'react';

const SidebarContentSingleSongView = ({ song }) => (
  <>
    <div className="mb-8">
      <img src={song.coverImageUrl || "/api/placeholder/400/400"} alt="Song cover" className="w-full h-auto rounded-lg shadow-lg" />
    </div>

    {song.tags && song.tags.length > 0 && (
      <>
        <h2 className="text-xl font-semibold mb-4 text-white font-heading">Tags</h2>
        <div className="flex flex-wrap">
          {song.tags.map((tag, index) => (
            <span key={index} className="bg-gray-700 text-yellow-400 px-3 py-1 rounded-full text-sm mr-2 mb-2 hover:bg-gray-600 transition-colors cursor-pointer">
              {tag}
            </span>
          ))}
        </div>
      </>
    )}
  </>
);

export default SidebarContentSingleSongView;