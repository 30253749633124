// src/hooks/useAuth.js
import { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';

const useAuth = (onClose) => {
  const [error, setError] = useState(null);

  const generateUniqueRandomUsername = async () => {
    let username;
    let isUnique = false;
    while (!isUnique) {
      username = 'user_' + Math.random().toString(36).substr(2, 9);
      const q = query(collection(db, "users"), where("username", "==", username));
      const querySnapshot = await getDocs(q);
      isUnique = querySnapshot.empty;
    }
    console.log("Generierter Benutzername:", username);
    return username;
  };

  const handleRegister = async (email, password, confirmPassword) => {
    setError(null);

    if (password !== confirmPassword) {
      setError("Die Passwörter stimmen nicht überein.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      const randomUsername = await generateUniqueRandomUsername();
      
      // Save user data to Firestore
      const userData = {
        uid: user.uid,
        email: user.email,
        username: randomUsername,
        emailVerified: false,
        disabled: false,
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        group: 'standard', // Standardgruppe für neue Benutzer
        isAdmin: false, // Standardmäßig kein Admin
        downloadedSongs: 0 // Zähler für heruntergeladene Songs
      };
      await setDoc(doc(db, "users", user.uid), userData);
      console.log("User registered and data saved to Firestore");
      onClose();
    } catch (error) {
      console.error("Error in registration process:", error);
      setError(error.message);
    }
  };

  const handleLogin = async (email, password) => {
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Aktualisieren Sie das letzte Anmeldedatum
      await updateDoc(doc(db, "users", user.uid), { 
        lastLogin: new Date().toISOString() 
      });

      console.log("User signed in successfully");
      onClose();
    } catch (error) {
      console.error("Error in login process:", error);
      setError(error.message);
    }
  };

  return { error, handleRegister, handleLogin };
};

export default useAuth;