import React from 'react';
import { useAudio } from '../contexts/AudioContext';
import SongCard from './songs/SongCard';
import SongRow from './songs/SongRow';

const SongLists = ({ topRatedSongs, latestSongs, lastDownloadedSongs }) => {
  const { playSong } = useAudio();

  const handlePlay = (song) => {
    playSong(song);
  };

  return (
    <div className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h2 className="text-3xl font-bold mb-6 font-heading">Top Rated</h2>
            {topRatedSongs.length > 0 && (
              <SongCard song={topRatedSongs[0]} onPlay={() => handlePlay(topRatedSongs[0])} />
            )}
            {topRatedSongs.slice(1).map((song) => (
              <SongRow key={song.id} song={song} onPlay={() => handlePlay(song)} />
            ))}
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-6 font-heading">Latest Songs</h2>
            {latestSongs.length > 0 && (
              <SongCard song={latestSongs[0]} onPlay={() => handlePlay(latestSongs[0])} />
            )}
            {latestSongs.slice(1).map((song) => (
              <SongRow key={song.id} song={song} onPlay={() => handlePlay(song)} />
            ))}
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-6 font-heading">Last Downloaded</h2>
            {lastDownloadedSongs.length > 0 && (
              <SongCard song={lastDownloadedSongs[0]} onPlay={() => handlePlay(lastDownloadedSongs[0])} />
            )}
            {lastDownloadedSongs.slice(1).map((song) => (
              <SongRow key={song.id} song={song} onPlay={() => handlePlay(song)} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SongLists;