// src/utils/songManagement.js

import { db, auth } from '../firebase';
import { collection, addDoc, doc, getDoc, updateDoc, increment, query, where, getDocs } from 'firebase/firestore';
import { updateSongCount } from './statsManagement';

const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const saveSongData = async (songData) => {
  console.log('Attempting to save song data to Firestore');
  console.log('Original song data:', songData);

  const user = auth.currentUser;
  if (!user) {
    throw new Error("User must be logged in to upload a song");
  }

  await user.getIdToken(true);  // Force token refresh
  const token = await user.getIdTokenResult(true);
  const userDoc = await getDoc(doc(db, 'users', user.uid));
  const adminStatus = token.claims.admin || (userDoc.exists() && userDoc.data().isAdmin);

  console.log('User ID:', user.uid);
  console.log('Token claims:', token.claims);
  console.log('User document data:', userDoc.data());
  console.log('Admin status:', adminStatus);
  console.log('Is admin from token:', token.claims.admin);
  console.log('Is admin from Firestore:', userDoc.exists() ? userDoc.data().isAdmin : 'User doc does not exist');
  const isAdmin = token.claims.admin || (userDoc.exists() && userDoc.data().isAdmin);
  console.log('Final isAdmin value:', isAdmin);

  if (!isAdmin) {
    throw new Error("Only admins can upload songs");
  }

  console.log('BPM before processing:', songData.bpm);
  console.log('BPM type:', typeof songData.bpm);

  let processedBpm = null;
  if (songData.bpm !== undefined && songData.bpm !== null && songData.bpm !== '') {
    processedBpm = Number(songData.bpm);
    if (isNaN(processedBpm)) {
      processedBpm = null;
    }
  }

  const slug = createSlug(songData.title);

  const updatedSongData = {
    ...songData,
    genreName: songData.genreName || songData.genre || 'Unknown',
    bpm: processedBpm,  // Use the processed BPM value
    uploadedBy: user.uid,
    uploadedAt: new Date(),
    downloads: 0, // Initialize downloads to 0
    upvotes: 0,
    downvotes: 0,
    rating: 0,
    slug: slug
  };

  // Check if slug already exists
  const slugQuery = query(collection(db, 'songs'), where('slug', '==', slug));
  let slugQuerySnapshot = await getDocs(slugQuery);

  if (!slugQuerySnapshot.empty) {
    // If slug exists, append a number to make it unique
    let counter = 1;
    let newSlug = slug;
    while (!slugQuerySnapshot.empty) {
      newSlug = `${slug}-${counter}`;
      counter++;
      const newSlugQuery = query(collection(db, 'songs'), where('slug', '==', newSlug));
      slugQuerySnapshot = await getDocs(newSlugQuery);
    }
    updatedSongData.slug = newSlug;
  }

  console.log('BPM after processing:', updatedSongData.bpm);
  console.log('BPM type after processing:', typeof updatedSongData.bpm);

  if ('genre' in updatedSongData) {
    delete updatedSongData.genre;
  }

  console.log('Updated song data to be saved:', updatedSongData);

  try {
    const songsCollection = collection(db, 'songs');
    const docRef = await addDoc(songsCollection, updatedSongData);
    console.log('Song data saved successfully. Document ID:', docRef.id);
    
    await updateSongCount();
    
    return docRef.id;
  } catch (error) {
    console.error('Error in saveSongData:', error);
    console.error('Error code:', error.code);
    console.error('Error message:', error.message);
    throw error;
  }
};

export const voteSong = async (songId, voteType) => {
  try {
    const songRef = doc(db, 'songs', songId);
    const field = voteType === 'upvote' ? 'upvotes' : 'downvotes';
    await updateDoc(songRef, {
      [field]: increment(1)
    });
    console.log(`Song ${voteType}d successfully`);
  } catch (error) {
    console.error(`Error ${voteType}ing song:`, error);
    throw error;
  }
};

export const incrementDownloads = async (songId) => {
  try {
    const songRef = doc(db, 'songs', songId);
    await updateDoc(songRef, {
      downloads: increment(1)
    });
    console.log(`Downloads incremented for song ${songId}`);
  } catch (error) {
    console.error('Error incrementing downloads:', error);
    throw error;
  }
};