import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume2, Loader, X } from 'lucide-react';
import { useAudio } from '../contexts/AudioContext';

const AudioPlayerModal = () => {
  const { 
    currentSong, 
    playSong, 
    pauseSong, 
    audioRef, 
    isLoading, 
    volume, 
    changeVolume, 
    isModalOpen, 
    closeModal 
  } = useAudio();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const progressRef = useRef(null);
  const containerRef = useRef(null);

  const handleSeek = useCallback((e) => {
    if (progressRef.current && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const width = rect.width;
      const percentage = x / width;
      const newTime = percentage * duration;
      if (audioRef.current && !isNaN(newTime)) {
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
    }
  }, [duration, audioRef]); // Added audioRef to the dependency array

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateTime = () => {
        if (!isDragging) {
          setCurrentTime(audio.currentTime);
        }
      };
      const updateDuration = () => {
        setDuration(audio.duration);
      };
      const handlePlay = () => {
        console.log('Audio play event triggered');
      };
      const handlePause = () => {
        console.log('Audio pause event triggered');
      };
      const handleEnded = () => {
        console.log('Audio ended');
      };

      audio.addEventListener('loadedmetadata', updateDuration);
      audio.addEventListener('timeupdate', updateTime);
      audio.addEventListener('play', handlePlay);
      audio.addEventListener('pause', handlePause);
      audio.addEventListener('ended', handleEnded);

      return () => {
        audio.removeEventListener('loadedmetadata', updateDuration);
        audio.removeEventListener('timeupdate', updateTime);
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('pause', handlePause);
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, [audioRef, isDragging, handleSeek]);

  const togglePlay = () => {
    console.log('Toggle play called, currentSong:', currentSong);
    if (currentSong) {
      if (audioRef.current.paused) {
        playSong(currentSong).catch(error => {
          console.error("Error playing song:", error);
        });
      } else {
        pauseSong();
      }
    }
  };

  const handleSkip = (seconds) => {
    if (audioRef.current) {
      audioRef.current.currentTime += seconds;
    }
  };

  const handleMouseDown = (e) => {
    handleSeek(e);
    setIsDragging(true);
  };

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      handleSeek(e);
    }
  }, [isDragging, handleSeek]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    changeVolume(newVolume);
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  if (!currentSong || !isModalOpen) return null;

  return (
    <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-800 bg-opacity-90 p-4 border-2 border-yellow-400 rounded-lg shadow-lg max-w-3xl w-full">
      <div className="relative">
        <button
          onClick={closeModal}
          className="absolute right-0 top-0 text-gray-400 hover:text-white"
        >
          <X size={24} />
        </button>
        <div className="flex items-center justify-between mb-2 pr-8">
          <span className="text-sm text-gray-400 truncate flex-grow mr-4">{currentSong.title} - {currentSong.artist}</span>
          <span className="text-sm text-gray-400 flex-shrink-0">
            {formatTime(currentTime)} / {formatTime(duration)}
          </span>
        </div>
        <div 
          ref={containerRef} 
          className="relative w-full h-2 bg-gray-600 rounded-lg cursor-pointer" 
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div
            ref={progressRef}
            className="absolute top-0 left-0 h-full bg-yellow-400 rounded-lg"
            style={{ width: `${(currentTime / duration) * 100}%` }}
          ></div>
        </div>
        <div className="flex items-center justify-center space-x-4 mt-4">
          <button onClick={() => handleSkip(-10)} className="text-gray-400 hover:text-white transition-colors">
            <SkipBack size={24} />
          </button>
          <button onClick={togglePlay} className="p-2 rounded-full bg-yellow-400 hover:bg-yellow-300 text-gray-900 transition-colors" disabled={isLoading}>
            {isLoading ? (
              <Loader size={24} className="animate-spin" />
            ) : audioRef.current.paused ? (
              <Play size={24} />
            ) : (
              <Pause size={24} />
            )}
          </button>
          <button onClick={() => handleSkip(10)} className="text-gray-400 hover:text-white transition-colors">
            <SkipForward size={24} />
          </button>
          <div className="flex items-center">
            <Volume2 size={24} className="text-gray-400 mr-2" />
            <input 
              type="range" 
              min="0" 
              max="1" 
              step="0.01" 
              value={volume} 
              onChange={handleVolumeChange} 
              className="w-24 h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerModal;