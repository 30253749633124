import React from 'react';
import PageLayout from './layout/PageLayout';

const PlaceholderPage = ({ title }) => {
  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-3xl font-bold mb-4 font-heading">{title}</h1>
          <p>This is a placeholder for the {title} page. Content coming soon.</p>
        </div>
      </div>
    </PageLayout>
  );
};

export default PlaceholderPage;