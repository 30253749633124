import React from 'react';
import { Link } from 'react-router-dom';
import { PlayCircle, Download } from 'lucide-react';
import { useAudio } from '../../contexts/AudioContext';
import { downloadSong } from '../../utils/downloadSong';

const SongCard = ({ song }) => {
  const { playSong } = useAudio();

  const handleDownload = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await downloadSong(song);
    } catch (error) {
      alert("Failed to download the song. Please try again later.");
    }
  };

  const handlePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    playSong(song).catch(error => {
      console.error("Error playing song:", error);
    });
  };

  return (
    <Link to={`/song/${song.slug}`} className="block">
      <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg mb-4 hover:bg-gray-700 transition-colors">
        <img src={song.coverImageUrl || "https://via.placeholder.com/300"} alt={song.title} className="w-full h-48 object-cover" />
        <div className="p-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-bold text-yellow-400 truncate flex-grow font-heading">{song.title}</h3>
            <div className="flex items-center ml-2">
              <span className="text-green-500 mr-2">↑{song.upvotes}</span>
              <span className="text-red-500">↓{song.downvotes}</span>
            </div>
          </div>
          <p className="text-gray-400 truncate mt-1">{song.artist}</p>
          <div className="flex justify-between mt-4">
            <button onClick={handlePreview} className="bg-yellow-500 text-black px-3 py-1 rounded-md flex items-center">
              <PlayCircle size={16} className="mr-1" /> Preview
            </button>
            <button onClick={handleDownload} className="bg-green-500 text-white px-3 py-1 rounded-md flex items-center">
              <Download size={16} className="mr-1" /> Download
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SongCard;