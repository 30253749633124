import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SongTable from './SongTable';
import SongGrid from './SongGrid';
import SongFilters from './SongFilters';
import useSongLibrary from './useSongLibrary';
import PageLayout from '../layout/PageLayout';  // Make sure this import is correct

const SongLibrary = () => {
  const location = useLocation();
  const {
    viewMode,
    setViewMode,
    songs,
    isLoading,
    currentlyPlaying,
    filterCriteria,
    setFilterCriteria,
    handleSongClick,
    playSong,
    pauseSong,
    handleApplyFilter,
  } = useSongLibrary();

  // Extract search term from location state and update filter criteria
  useEffect(() => {
    if (location.state && location.state.searchTerm) {
      setFilterCriteria((prev) => ({ ...prev, search: location.state.searchTerm }));
    }
  }, [location.state, setFilterCriteria]);

  return (
    <PageLayout>
      <main className="bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-4xl font-bold text-white mb-8 font-heading">Song Library</h1>
          
          <div className="lg:hidden mb-4">
            <SongFilters 
              filterCriteria={filterCriteria}
              setFilterCriteria={setFilterCriteria}
              handleApplyFilter={handleApplyFilter}
            />
          </div>

          <div className="flex flex-col lg:flex-row gap-8">
            <div className="w-full lg:w-3/4">
              {/* View mode buttons - only visible on larger screens */}
              <div className="mb-4 hidden lg:flex justify-end">
                <button
                  onClick={() => setViewMode('table')}
                  className={`px-4 py-2 rounded-l-md ${viewMode === 'table' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                >
                  Table
                </button>
                <button
                  onClick={() => setViewMode('grid')}
                  className={`px-4 py-2 rounded-r-md ${viewMode === 'grid' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                >
                  Grid
                </button>
              </div>
              
              {isLoading ? (
                <div className="text-white text-center py-10">Loading songs...</div>
              ) : songs.length === 0 ? (
                <div className="text-red-500 text-center py-10 text-xl font-bold">
                  No entries found. Please try different search criteria.
                </div>
              ) : (
                <div className="lg:hidden">
                  <SongGrid 
                    songs={songs} 
                    currentlyPlaying={currentlyPlaying} 
                    handleSongClick={handleSongClick} 
                    playSong={playSong} 
                    pauseSong={pauseSong}
                  />
                </div>
              )}
              {!isLoading && songs.length > 0 && (
                <>
                  <div className="lg:hidden">
                    <SongGrid 
                      songs={songs} 
                      currentlyPlaying={currentlyPlaying} 
                      handleSongClick={handleSongClick} 
                      playSong={playSong} 
                      pauseSong={pauseSong}
                    />
                  </div>
                  <div className="hidden lg:block">
                    {viewMode === 'table' ? (
                      <SongTable 
                        songs={songs} 
                        currentlyPlaying={currentlyPlaying} 
                        handleSongClick={handleSongClick} 
                        playSong={playSong} 
                        pauseSong={pauseSong}
                      />
                    ) : (
                      <SongGrid 
                        songs={songs} 
                        currentlyPlaying={currentlyPlaying} 
                        handleSongClick={handleSongClick} 
                        playSong={playSong} 
                        pauseSong={pauseSong}
                      />
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="hidden lg:block lg:w-1/4">
              <SongFilters 
                filterCriteria={filterCriteria}
                setFilterCriteria={setFilterCriteria}
                handleApplyFilter={handleApplyFilter}
              />
            </div>
          </div>
        </div>
      </main>
      <audio id="audioPlayer" />
    </PageLayout>
  );
};

export default SongLibrary;