import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { checkUserRole } from '../utils/userRoles';
import { updateSongCount as manualUpdateSongCount } from '../utils/statsManagement';
import SubmitSongPage from './SubmitSongPage';
import { Button } from "@/components/ui/button";

const AdminPanel = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const role = await checkUserRole(user.uid);
        setIsAdmin(role.isAdmin);
      } else {
        navigate('/admin');
      }
      setLoading(false);
    };
    checkAdminStatus();
  }, [navigate]);

  const handleManualUpdate = async () => {
    setIsUpdating(true);
    setUpdateMessage('');
    try {
      const updatedCount = await manualUpdateSongCount();
      setUpdateMessage(`Song count successfully updated to ${updatedCount}`);
    } catch (error) {
      setUpdateMessage('Error updating song count. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  if (!isAdmin) {
    return <div className="text-white">Access denied. You must be an admin to view this page.</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8 font-heading">Admin Panel</h1>
        
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-2xl font-semibold text-white mb-4 font-heading">Manual Song Count Update</h2>
          <p className="text-gray-300 mb-4">Click the button below to manually update the song count in the database.</p>
          <Button 
            onClick={handleManualUpdate} 
            disabled={isUpdating}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            {isUpdating ? 'Updating...' : 'Update Song Count'}
          </Button>
          {updateMessage && <p className="mt-2 text-green-400">{updateMessage}</p>}
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold text-white mb-4 font-heading">Submit New Song</h2>
          <SubmitSongPage />
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;