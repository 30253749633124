import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageLayout from './layout/PageLayout';
import Header from './layout/Header';
import SongLists from './SongLists';
import AuthModal from './auth/AuthModal';
import NewsletterSignupModal from './NewsletterSignupModal';
import useFetchSongs from '../hooks/useFetchSongs';
import winImage from '../assets/win.png';
import communityImage from '../assets/community.png';
import { FEATURES } from '../config/features';

const MainPage = ({ user }) => {
  const navigate = useNavigate();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [isFromSubmitSong, setIsFromSubmitSong] = useState(false);
  const { topRatedSongs, latestSongs, lastDownloadedSongs } = useFetchSongs();

  const handleSubmitSong = () => {
    if (user) {
      navigate('/submit-song');
    } else {
      setIsFromSubmitSong(true);
      setIsAuthModalOpen(true);
    }
  };

  return (
    <PageLayout>
      <Header />
      <main className="bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {FEATURES.FEATURED_SONGS_FLAG && (
            <div className="mb-8">
              <div className="flex justify-between items-center">
                <div className="relative">
                  <h2 className="text-3xl font-extrabold text-white font-heading">
                    Featured Songs
                  </h2>
                  <div className="absolute -top-2 -right-12 bg-yellow-400 text-black px-2 py-1 rounded-md text-sm font-bold transform rotate-12">
                    NEW
                  </div>
                </div>
                <Link
                  to="/library"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  View All Songs
                </Link>
              </div>
            </div>
          )}
          
          <SongLists 
            topRatedSongs={topRatedSongs}
            latestSongs={latestSongs}
            lastDownloadedSongs={lastDownloadedSongs}
          />

          {FEATURES.PUBLIC_UPLOAD && (
            <div className="mt-12 text-center">
              <button
                onClick={handleSubmitSong}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Submit A Song
              </button>
            </div>
          )}
        </div>

        {/* Subscription Promotion Section */}
        <div className="bg-gray-800 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="lg:w-2/3">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl font-heading">
                  Win a subscription!
                </h2>
                <p className="mt-3 max-w-3xl text-lg text-gray-300">
                  Subscribe to our newsletter for exclusive updates and be among the first to learn how you can win a free subscription to your preferred AI music generator. Unleash your creativity with unlimited access to cutting-edge AI-powered music production tools!
                </p>
                <div className="mt-8">
                  <button
                    onClick={() => setIsNewsletterModalOpen(true)}
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500"
                  >
                    Get More Info
                  </button>
                </div>
              </div>
              <div className="mt-8 lg:mt-0 lg:w-1/3">
                <img
                  className="rounded-lg shadow-xl w-full"
                  src={winImage}
                  alt="Subscription benefits"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Community Section */}
        <div className="bg-gray-900 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col-reverse lg:flex-row lg:items-center lg:justify-between lg:space-x-12">
              <div className="lg:w-1/3 mt-8 lg:mt-0">
                <img
                  className="rounded-lg shadow-xl w-full"
                  src={communityImage}
                  alt="Community"
                />
              </div>
              <div className="lg:w-2/3">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl font-heading">
                  Join Our Community!
                </h2>
                <p className="mt-3 max-w-3xl text-lg text-gray-300">
                  Join our Discord to discuss your music, regardless of which tool you used to generate it. Connect with fellow music enthusiasts, share your creations, and stay updated on the latest AI music generation trends!
                </p>
                <div className="mt-8">
                  <a
                    href="https://discord.gg/8xsN3DJv"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Join Discord
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => {
          setIsAuthModalOpen(false);
          setIsFromSubmitSong(false);
        }} 
        isFromSubmitSong={isFromSubmitSong}
      />
      <NewsletterSignupModal
        isOpen={isNewsletterModalOpen}
        onClose={() => setIsNewsletterModalOpen(false)}
      />
    </PageLayout>
  );
};

export default MainPage;