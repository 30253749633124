import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Youtube } from 'lucide-react';

const Footer = () => {
  const handleDMCAClick = (e) => {
    if (window.location.pathname === '/dmca') {
      e.preventDefault();
      window.scrollTo(0, 0);
    }
  };

  return (
    <footer className="bg-gray-800 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
  <h3 className="text-lg font-semibold mb-4 font-heading">About Us</h3>
  <p className="text-sm">MeloDroid is your gateway to free AI-generated music. Our mission is to make music creation accessible to everyone, whether you're a seasoned artist or just love great tunes.</p>
</div>
          <div>
            <h3 className="text-lg font-semibold mb-4 font-heading">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-white transition-colors">Home</Link></li>
              <li><Link to="/library" className="hover:text-white transition-colors">Song Library</Link></li>
              <li><Link to="/faq" className="hover:text-white transition-colors">FAQ</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 font-heading">Contact Us</h3>
            <p className="text-sm">Email: support@loremlorem.com</p>
            <p className="text-sm">Discord: Join our community</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 font-heading">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://discord.gg/8xsN3DJv" className="hover:text-white transition-colors"><Facebook size={24} /></a>
              <a href="https://discord.gg/8xsN3DJv" className="hover:text-white transition-colors"><Twitter size={24} /></a>
              <a href="https://discord.gg/8xsN3DJv" className="hover:text-white transition-colors"><Instagram size={24} /></a>
              <a href="https://discord.gg/8xsN3DJv" className="hover:text-white transition-colors"><Youtube size={24} /></a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-sm">&copy; 2024 LoremIpsum. All rights reserved.</p>
          <div className="mt-4 space-x-4">
            <Link to="/imprint" className="text-sm hover:text-white transition-colors">Imprint</Link>
            <Link to="/gdpr" className="text-sm hover:text-white transition-colors">GDPR</Link>
            <Link to="/dmca" className="text-sm hover:text-white transition-colors" onClick={handleDMCAClick}>DMCA</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);