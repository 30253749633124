import { getPresignedUrl } from './r2Utils';

export const uploadFile = async (file, path) => {
  console.log(`Attempting to upload file to path: ${path}`);
  console.log('File details:', { name: file.name, type: file.type, size: file.size });

  try {
    // Get a pre-signed URL for the upload
    const signedUrl = await getPresignedUrl(path);
    console.log('Received signed URL:', signedUrl);

    // Replace the R2 URL with your Cloudflare Worker URL
    const workerUrl = 'https://api.melodroid.com' + new URL(signedUrl).pathname + new URL(signedUrl).search;
    console.log('Worker URL:', workerUrl);
    
    // Perform the upload using the Worker URL
    const response = await fetch(workerUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', response.status, response.statusText, errorText);
      throw new Error(`HTTP error! status: ${response.status}, statusText: ${response.statusText}, body: ${errorText}`);
    }

    console.log('Upload response:', response.status, response.statusText);

    // Construct the download URL using the provided custom domain
    const downloadURL = `${process.env.REACT_APP_R2_DOWNLOAD_URL}/${path}`;
    console.log('Download URL:', downloadURL);

    return downloadURL;
  } catch (error) {
    console.error('Error in uploadFile:', error);
    console.error('Error stack:', error.stack);
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.error('This error might be due to CORS issues. Check your CORS configuration on the server side.');
    }
    throw error;
  }
};

export const extractAudioDuration = async (file) => {
  console.log('Extracting audio duration for file:', file.name);
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.onloadedmetadata = () => {
      console.log('Audio duration extracted:', audio.duration);
      resolve(audio.duration);
    };
    audio.onerror = (error) => {
      console.error('Error extracting audio duration:', error);
      reject(error);
    };
    audio.src = URL.createObjectURL(file);
  });
};