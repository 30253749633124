import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import Select from 'react-select';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { getGenres } from '../../utils/genreManagement';
import { Checkbox } from "@/components/ui/checkbox";

const SongFilters = ({ filterCriteria, setFilterCriteria, handleApplyFilter }) => {
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const fetchedGenres = await getGenres();
        const genreOptions = fetchedGenres.map(genre => ({
          value: genre.id,
          label: genre.name
        }));
        setGenres([{ value: 'Any', label: 'Any Genre' }, ...genreOptions]);
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleGenreChange = (selectedOption) => {
    setFilterCriteria({ ...filterCriteria, genre: selectedOption });
  };

  const dateOptions = [
    { value: 'Any', label: 'Any Time' },
    { value: 'LastWeek', label: 'Last Week' },
    { value: 'LastMonth', label: 'Last Month' },
    { value: 'LastYear', label: 'Last Year' },
  ];

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    handleApplyFilter();
  };

  const handleDurationChange = (selectedOption) => {
    setFilterCriteria({ ...filterCriteria, duration: selectedOption });
  };

  return (
    <div className="bg-gray-800 p-4 rounded-lg">
      <h2 className="text-xl font-bold mb-4 font-heading">Filters</h2>
      
      <div className="space-y-4">
        {/* Instrumentals checkbox */}
        <div className="flex items-center space-x-3 mb-4">
          <Checkbox
            id="instrumentals"
            checked={filterCriteria.instrumentalsOnly}
            onCheckedChange={(checked) =>
              setFilterCriteria({ ...filterCriteria, instrumentalsOnly: checked })
            }
            className="w-6 h-6 border-2 border-white"
          />
          <label
            htmlFor="instrumentals"
            className="text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-white"
          >
            Instrumentals Only
          </label>
        </div>

        <div>
          <label htmlFor="search" className="block text-sm font-medium text-gray-300 mb-1">
            Search
          </label>
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              type="text"
              id="search"
              name="search"
              placeholder="Search songs..."
              value={filterCriteria.search}
              onChange={handleInputChange}
              className="pl-8 bg-gray-700 text-white"
            />
          </div>
        </div>

        <div>
          <label htmlFor="submittedDate" className="block text-sm font-medium text-gray-300 mb-1">
            Submitted Date
          </label>
          <Select
            options={dateOptions}
            value={dateOptions.find(option => option.value === filterCriteria.submittedDate)}
            onChange={(selectedOption) => setFilterCriteria({ ...filterCriteria, submittedDate: selectedOption.value })}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#4B5563',
                primary: '#3B82F6',
                neutral0: '#374151',
                neutral80: 'white',
              },
            })}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
                borderColor: '#4B5563',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#4B5563' : '#374151',
                color: 'white',
              }),
            }}
          />
        </div>

        <div>
          <label htmlFor="genre" className="block text-sm font-medium text-gray-300 mb-1">
            Genre
          </label>
          <Select
            options={genres}
            value={filterCriteria.genre}
            onChange={handleGenreChange}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#4B5563',
                primary: '#3B82F6',
                neutral0: '#374151',
                neutral80: 'white',
              },
            })}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
                borderColor: '#4B5563',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#4B5563' : '#374151',
                color: 'white',
              }),
            }}
          />
        </div>

        <div>
          <label htmlFor="tempo" className="block text-sm font-medium text-gray-300 mb-1">
            Tempo
          </label>
          <Select
            options={[
              { value: 'Any', label: 'Any Tempo' },
              { value: 'Slow', label: 'Slow (0-90 BPM)' },
              { value: 'Medium', label: 'Medium (91-140 BPM)' },
              { value: 'Fast', label: 'Fast (141+ BPM)' },
            ]}
            value={filterCriteria.tempo}
            onChange={(selectedOption) => setFilterCriteria({ ...filterCriteria, tempo: selectedOption })}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#4B5563',
                primary: '#3B82F6',
                neutral0: '#374151',
                neutral80: 'white',
              },
            })}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
                borderColor: '#4B5563',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#4B5563' : '#374151',
                color: 'white',
              }),
            }}
          />
        </div>

        <div>
          <label htmlFor="duration" className="block text-sm font-medium text-gray-300 mb-1">
            Duration
          </label>
          <Select
            options={[
              { value: 'Any', label: 'Any Duration' },
              { value: 'Short', label: 'Short (0-2 minutes)' },
              { value: 'Medium', label: 'Medium (2-4 minutes)' },
              { value: 'Long', label: 'Long (4+ minutes)' },
            ]}
            value={filterCriteria.duration}
            onChange={handleDurationChange}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#4B5563',
                primary: '#3B82F6',
                neutral0: '#374151',
                neutral80: 'white',
              },
            })}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
                borderColor: '#4B5563',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#374151',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#4B5563' : '#374151',
                color: 'white',
              }),
            }}
          />
        </div>

        <form onSubmit={handleSearchSubmit}>
          <Button type="submit" className="mt-4 w-full">
            Apply Filters
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SongFilters;