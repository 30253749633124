import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImage from '../../assets/header.webp';
import { useSongCount } from '../../hooks/useSongCount';

const Header = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const songCount = useSongCount();
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    navigate('/library', { state: { searchTerm } });
  };

  return (
    <header className="w-full relative">
      <img 
        src={headerImage} 
        alt="Song Library Header" 
        className="w-full h-96 object-cover"
      />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4 sm:mb-6 md:mb-8 font-heading text-center">
          Search Over <span className="text-yellow-400">{songCount !== null ? songCount : '...'}</span> free AI Songs for your Project
        </h1>
        
        <form onSubmit={handleSearch} className="w-full max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-3xl flex flex-col sm:flex-row gap-2">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
            className="flex-grow bg-gray-700 text-white px-4 py-2 rounded-md sm:rounded-l-md sm:rounded-r-none focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md sm:rounded-l-none sm:rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Search
          </button>
        </form>
      </div>
    </header>
  );
};

export default React.memo(Header);