import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import ScrollToTop from '../ScrollToTop';

const PageLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900">
      <Navigation />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default PageLayout;