import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume2 } from 'lucide-react';

const AudioPlayer = ({ song }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);
  const progressRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
      audio.addEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
      return () => {
        audio.removeEventListener('loadedmetadata', () => setDuration(audio.duration));
        audio.removeEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
      };
    }
  }, [song]);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSkip = (seconds) => {
    if (audioRef.current) {
      audioRef.current.currentTime += seconds;
    }
  };

  const handleSeek = (e) => {
    const progressBar = progressRef.current;
    const percent = e.nativeEvent.offsetX / progressBar.offsetWidth;
    audioRef.current.currentTime = percent * duration;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4 text-white font-heading">Audio Player</h2>
      <audio ref={audioRef} src={song.songFileUrl} />
      <div className="bg-gray-700 p-4 rounded-lg">
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400">{formatTime(currentTime)}</span>
          <span className="text-sm text-gray-400">{formatTime(duration)}</span>
        </div>
        <div 
          ref={progressRef}
          onClick={handleSeek} 
          className="h-1 bg-gray-600 rounded-full cursor-pointer mb-4"
        >
          <div 
            className="h-full bg-yellow-400 rounded-full" 
            style={{ width: `${(currentTime / (song.duration || 1)) * 100}%` }}
          ></div>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <button onClick={() => handleSkip(-10)} className="text-gray-400 hover:text-white transition-colors">
            <SkipBack size={24} />
          </button>
          <button onClick={togglePlay} className="p-2 rounded-full bg-yellow-400 hover:bg-yellow-300 text-gray-900 transition-colors">
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button onClick={() => handleSkip(10)} className="text-gray-400 hover:text-white transition-colors">
            <SkipForward size={24} />
          </button>
          <div className="flex items-center">
            <Volume2 size={24} className="text-gray-400 mr-2" />
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className="w-24 h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;