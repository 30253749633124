import React, { useEffect } from 'react';
import PageLayout from './layout/PageLayout';

const DMCAPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-3xl font-bold mb-6 font-heading">DMCA Policy</h1>
          <p className="mb-6">This Digital Millennium Copyright Act (DMCA) policy applies to RagnaCustoms ("Website") and its associated services. It outlines our approach to copyright infringement notifications and how you can submit a complaint.</p>
          
          <h2 className="text-2xl font-semibold mb-4 font-heading">Intellectual Property Protection</h2>
          <p className="mb-6">We value intellectual property rights and expect our users to do the same. We promptly respond to clear notifications of alleged copyright infringement that comply with the DMCA of 1998.</p>
          
          <h2 className="text-2xl font-semibold mb-4 font-heading">Before Submitting a Complaint</h2>
          <p className="mb-6">If you're unsure whether material on our site infringes on copyrights, consider consulting an attorney. The DMCA requires personal information in copyright infringement notifications. If you have privacy concerns, you may wish to engage an agent to report infringements on your behalf.</p>
          
          <h2 className="text-2xl font-semibold mb-4 font-heading">Infringement Notifications</h2>
          <p className="mb-4">Copyright owners or their agents who believe our services host infringing material may submit a written notification using the contact details below. All notifications must comply with DMCA requirements.</p>
          <p className="mb-4">Submitting a DMCA complaint initiates a legal process. We'll review your complaint for accuracy, validity, and completeness. If it meets the requirements, we may remove or restrict access to the allegedly infringing material and potentially terminate accounts of repeat infringers.</p>
          <p className="mb-4">If we take action in response to a notification, we'll make a good faith effort to inform the affected user about the removal or access restriction.</p>
          <p className="mb-6">We reserve the right to not act on DMCA notifications that fail to comply with all DMCA requirements.</p>
          
          <h2 className="text-2xl font-semibold mb-4 font-heading">Policy Updates</h2>
          <p className="mb-6">We may modify this policy at any time. Changes become effective upon posting an updated version on the Website. The date at the bottom of this page will be revised accordingly.</p>
          
          <h2 className="text-2xl font-semibold mb-4 font-heading">Reporting Copyright Infringement</h2>
          <p className="mb-6">To report infringing material or activity, please email us at dmca@ragnacustoms.com with "[DMCA]" in the subject line.</p>
          
          <p className="text-sm italic">Last updated: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </PageLayout>
  );
};

export default DMCAPage;