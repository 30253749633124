import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { FEATURES } from './config/features';
import { checkUserRole } from './utils/userRoles';
import AutoScrollTop from './components/AutoScrollTop';
import ScrollToTop from './components/ScrollToTop';
import MainPage from './components/MainPage';
import SongLibrary from './components/songLibrary/SongLibrary';
import FAQPage from './components/FAQPage';
import PlaceholderPage from './components/PlaceholderPage';
import DMCAPage from './components/DMCAPage';
import UserProfilePage from './components/UserProfilePage';
import UserSettingsPage from './components/UserSettingsPage';
import SongDetailPage from './components/singleSongView/SongDetailPage';
import AdminLoginPage from './components/AdminLoginPage';
import AdminPanel from './components/AdminPanel';
import { AudioProvider } from './contexts/AudioContext';
import AudioPlayerModal from './components/AudioPlayerModal';

function App() {
  return (
    <Router>
      <AudioProvider>
        <AutoScrollTop />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/library" element={<SongLibrary />} />
          <Route path="/song/:slug" element={<SongDetailPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/imprint" element={<PlaceholderPage title="Imprint" />} />
          <Route path="/gdpr" element={<PlaceholderPage title="GDPR" />} />
          <Route path="/dmca" element={<DMCAPage />} />
          
          {FEATURES.PUBLIC_PROFILE && (
            <>
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/settings" element={<UserSettingsPage />} />
            </>
          )}
          
          {FEATURES.ADMIN_PANEL && (
            <>
              <Route path="/admin" element={<AdminLoginPage />} />
              <Route 
                path="/admin/panel" 
                element={
                  <ProtectedRoute>
                    <AdminPanel />
                  </ProtectedRoute>
                } 
              />
            </>
          )}
        </Routes>
        <ScrollToTop />
        <AudioPlayerModal />
      </AudioProvider>
    </Router>
  );
}

// ProtectedRoute component to check if user is admin
const ProtectedRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const role = await checkUserRole(user.uid);
        setIsAdmin(role.isAdmin);
      } else {
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAdmin ? children : <Navigate to="/admin" />;
};

export default App;