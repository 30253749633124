import React, { useState, useEffect } from 'react';
// import { useAudio } from '../contexts/AudioContext';
// import { Music, Tag } from 'lucide-react';
import CreatableSelect from 'react-select/creatable';
import { Input } from "@/components/ui/input";
import { getGenres } from '../utils/genreManagement';

const SongInfoForm = ({ onFormDataChange, formData }) => {
  const [genreOptions, setGenreOptions] = useState([]);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const genres = await getGenres();
        const options = genres.map(genre => ({
          value: genre.id,
          label: genre.name
        }));
        setGenreOptions(options);
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    onFormDataChange({ ...formData, [name]: newValue });
  };

  const handleGenreChange = (newValue) => {
    onFormDataChange({ ...formData, genre: newValue });
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="isInstrumental" className="flex items-center space-x-2 text-sm font-medium text-gray-300 font-heading">
          <input
            type="checkbox"
            id="isInstrumental"
            name="isInstrumental"
            className="form-checkbox h-4 w-4 text-blue-600 rounded focus:ring-blue-500"
            checked={formData.isInstrumental}
            onChange={handleInputChange}
          />
          <span>This is purely instrumental</span>
        </label>
      </div>
      
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-300 mb-1 font-heading">
          Song Title
        </label>
        <Input
          type="text"
          id="title"
          name="title"
          placeholder="Enter song title"
          value={formData.title}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label htmlFor="artist" className="block text-sm font-medium text-gray-300 mb-1 font-heading">
          Artist
        </label>
        <Input
          type="text"
          id="artist"
          name="artist"
          placeholder="Enter artist name"
          value={formData.artist}
          onChange={handleInputChange}
          required
        />
      </div>

      <div>
        <label htmlFor="genre" className="block text-sm font-medium text-gray-300 mb-1 font-heading">
          Genre
        </label>
        <CreatableSelect
          isClearable
          onChange={handleGenreChange}
          options={genreOptions}
          value={formData.genre}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select or type a genre"
          formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          styles={{
            control: (base) => ({
              ...base,
              backgroundColor: '#374151',
              borderColor: '#4B5563',
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: '#374151',
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#4B5563' : '#374151',
              color: 'white',
            }),
            singleValue: (base) => ({
              ...base,
              color: 'white',
            }),
            input: (base) => ({
              ...base,
              color: 'white',
            }),
          }}
        />
      </div>

      <div>
        <label htmlFor="bpm" className="block text-sm font-medium text-gray-300 mb-1 font-heading">
          Tempo (BPM)
        </label>
        <Input
          type="number"
          id="bpm"
          name="bpm"
          placeholder="Enter BPM"
          value={formData.bpm}
          onChange={handleInputChange}
          min="0"
          max="300"
        />
      </div>

      <div>
        <label htmlFor="tags" className="block text-sm font-medium text-gray-300 mb-1 font-heading">
          Tags
        </label>
        <Input
          type="text"
          id="tags"
          name="tags"
          placeholder="Enter tags separated by commas"
          value={formData.tags}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default SongInfoForm;