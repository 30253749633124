import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import SongInfoForm from './SongInfoForm';
import CoverImageUpload from './CoverImageUpload';
import SongFileUpload from './SongFileUpload';
import { uploadFile } from '../utils/fileUpload';
import { saveSongData } from '../utils/songManagement';
import { extractAudioDuration } from '../utils/fileUpload';
import { handleGenreSelection } from '../utils/genreManagement';
import { getUsernameByUid } from '../utils/userManagement';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const SubmitSongPage = () => {
  console.log('SubmitSongPage component is rendering');

  const [formData, setFormData] = useState({
    isInstrumental: false,
    title: '',
    artist: '',
    genre: null,
    tags: '',
    bpm: null
  });
  const [coverImage, setCoverImage] = useState(null);
  const [songFile, setSongFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdTokenResult();
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          console.log('User ID:', user.uid);
          console.log('Admin claim:', token.claims.admin);
          console.log('Firestore user data:', userDoc.data());
          console.log('Firestore isAdmin:', userDoc.data()?.isAdmin);
          console.log('All claims:', token.claims);
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      } else {
        console.log('No user is signed in.');
      }
    };

    checkAdminStatus();
  }, []);

  const handleFormDataChange = (newData) => {
    console.log('Form data changed:', newData);
    setFormData(prevData => ({ ...prevData, ...newData }));
  };

  const handleCoverImageChange = (file) => {
    console.log('Cover image changed:', file);
    setCoverImage(file);
  };

  const handleSongFileChange = (file) => {
    console.log('Song file changed:', file);
    setSongFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsUploading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User must be logged in to upload a song");
      }

      console.log('Checking admin status...');
      // Check if the user is an admin
      const token = await user.getIdTokenResult(true);
      console.log('Token claims:', token.claims);
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      console.log('User document data:', userDoc.data());
      const isAdmin = token.claims.admin || (userDoc.exists() && userDoc.data().isAdmin);
      console.log('Is admin?', isAdmin);

      if (!isAdmin) {
        throw new Error("Only admins can upload songs");
      }

      console.log('User authenticated:', user.uid);

      if (!songFile) {
        throw new Error("Please select a song file to upload");
      }

      if (!formData.title || !formData.artist) {
        throw new Error("Please provide both a title and an artist for the song");
      }

      if (!formData.genre) {
        throw new Error("Please select a genre for the song");
      }

      console.log('Uploading cover image...');
      let coverImageUrl = null;
      if (coverImage) {
        const coverImagePath = `cover-images/${Date.now()}_${coverImage.name}`;
        try {
          coverImageUrl = await uploadFile(coverImage, coverImagePath);
          console.log('Cover image uploaded successfully:', coverImageUrl);
        } catch (coverImageError) {
          console.error('Error uploading cover image:', coverImageError);
          throw new Error(`Failed to upload cover image: ${coverImageError.message}`);
        }
      }

      console.log('Uploading song file...');
      const songFilePath = `songs/${Date.now()}_${songFile.name}`;
      let songFileUrl;
      try {
        songFileUrl = await uploadFile(songFile, songFilePath);
        console.log('Song file uploaded successfully:', songFileUrl);
      } catch (songFileError) {
        console.error('Error uploading song file:', songFileError);
        throw new Error(`Failed to upload song file: ${songFileError.message}`);
      }

      console.log('Extracting audio duration...');
      const duration = await extractAudioDuration(songFile);
      console.log('Audio duration:', duration);

      console.log('Handling genre selection...');
      const { id: genreId, name: genreName } = await handleGenreSelection(formData.genre);
      console.log('Genre processed:', { id: genreId, name: genreName });

      console.log('Getting uploader username...');
      const uploaderUsername = await getUsernameByUid(user.uid);
      console.log('Uploader username:', uploaderUsername);

      const formattedBpm = formData.bpm ? Number(formData.bpm) : null;
      console.log('Formatted BPM:', formattedBpm);
      console.log('Formatted BPM type:', typeof formattedBpm);

      const songData = {
        title: formData.title,
        artist: formData.artist,
        isInstrumental: formData.isInstrumental,
        genreId: genreId,
        genreName: genreName,
        tags: formData.tags.split(',').map(tag => tag.trim()),
        coverImageUrl,
        songFileUrl,
        duration,
        uploadedBy: user.uid,
        uploaderUsername: uploaderUsername || 'Anonymous',
        uploadedAt: new Date(),
        lastDownloadedAt: null,
        upvotes: 0,
        downvotes: 0,
        rating: 0,
        downloads: 0,
        bpm: formattedBpm
      };

      console.log('Song data being sent to saveSongData:', songData);

      await saveSongData(songData);

      setSuccess(true);
      // Reset form after successful upload
      setFormData({
        isInstrumental: false,
        title: '',
        artist: '',
        genre: null,
        tags: '',
        bpm: null
      });
      setCoverImage(null);
      setSongFile(null);
    } catch (error) {
      console.error('Error uploading song:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
      setError(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-6">Submit a New Song</h2>
      
      {error && <div className="bg-red-600 text-white p-4 rounded-md mb-6">{error}</div>}
      {success && <div className="bg-green-600 text-white p-4 rounded-md mb-6">Song uploaded successfully!</div>}

      <form onSubmit={handleSubmit} className="space-y-6">
        <SongInfoForm onFormDataChange={handleFormDataChange} formData={formData} />
        <CoverImageUpload onFileChange={handleCoverImageChange} />
        <SongFileUpload onFileChange={handleSongFileChange} />
        <Button type="submit" disabled={isUploading} className="w-full">
          {isUploading ? 'Uploading...' : 'Submit Song'}
        </Button>
      </form>
    </div>
  );
};

export default SubmitSongPage;