import React from 'react';
import { PlayCircle, Pause, Download } from 'lucide-react';

const SongGrid = ({ songs, currentlyPlaying, handleSongClick, playSong, pauseSong }) => {
  const handlePlayPause = (e, song) => {
    e.stopPropagation();
    if (currentlyPlaying && currentlyPlaying.id === song.id) {
      pauseSong();
    } else {
      playSong(e, song);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {songs.map((song) => (
        <div 
          key={song.id} 
          className="bg-gray-800 rounded-lg overflow-hidden shadow-lg cursor-pointer hover:bg-gray-700" 
          onClick={() => handleSongClick(song)}
        >
          <img 
            src={song.coverImageUrl || "https://via.placeholder.com/300"} 
            alt={song.title} 
            className="w-full h-48 object-cover"
          />
          <div className="p-4">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-bold text-yellow-400 truncate flex-grow">{song.title}</h3>
              <div className="flex items-center ml-2">
                <span className="text-green-500 mr-2">↑{song.upvotes || 0}</span>
                <span className="text-red-500">↓{song.downvotes || 0}</span>
              </div>
            </div>
            <p className="text-gray-400 truncate mt-1">{song.artist}</p>
            <div className="flex justify-between mt-4">
              <button 
                className={`px-3 py-1 rounded-md flex items-center ${currentlyPlaying && currentlyPlaying.id === song.id ? 'bg-red-500 text-white' : 'bg-yellow-500 text-black'}`}
                onClick={(e) => handlePlayPause(e, song)}
              >
                {currentlyPlaying && currentlyPlaying.id === song.id ? 
                  <Pause size={16} className="mr-1" /> : 
                  <PlayCircle size={16} className="mr-1" />
                }
                {currentlyPlaying && currentlyPlaying.id === song.id ? 'Pause' : 'Preview'}
              </button>
              <button className="bg-green-500 text-white px-3 py-1 rounded-md flex items-center">
                <Download size={16} className="mr-1" /> Download
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SongGrid;