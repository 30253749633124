import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { httpBatchLink } from '@trpc/client';
// import { trpc } from './utils/trpc';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

// Remove or comment out the trpcClient configuration
/*
const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: 'https://your-api-gateway-url.amazonaws.com/trpc', // Replace with your actual API URL
    }),
  ],
});
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* Remove the trpc.Provider wrapper */}
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();