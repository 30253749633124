// src/components/PasswordInput.js
import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

const PasswordInput = ({ value, onChange, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-4 relative">
      <input 
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        className="w-full p-2 text-white bg-gray-700 rounded pr-10"
        value={value}
        onChange={onChange}
      />
      <button 
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
};

export default PasswordInput;