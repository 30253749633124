import React from 'react';

const SongHeader = React.memo(({ title }) => (
  <div className="relative h-48 bg-gradient-to-r from-blue-500 to-purple-600">
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <div className="absolute inset-0 flex items-center justify-center">
      <h1 className="text-4xl font-bold text-white text-center font-heading">{title}</h1>
    </div>
  </div>
));

export default SongHeader;