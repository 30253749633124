import React from 'react';
import { Link } from 'react-router-dom';
import { MoreVertical, Download, PlayCircle } from 'lucide-react';
import { useAudio } from '../../contexts/AudioContext';
import { downloadSong } from '../../utils/downloadSong';

const SongRow = ({ song }) => {
  const { playSong } = useAudio();

  const handleDownload = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await downloadSong(song);
    } catch (error) {
      alert("Failed to download the song. Please try again later.");
    }
  };

  const handlePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    playSong(song).catch(error => {
      console.error("Error playing song:", error);
    });
  };

  return (
    <Link to={`/song/${song.slug}`} className="block">
      <div className="flex items-center bg-gray-800 p-2 rounded-md mb-2 hover:bg-gray-700 transition-colors">
        <img src={song.coverImageUrl || "https://via.placeholder.com/100"} alt={song.title} className="w-12 h-12 flex-shrink-0 object-cover rounded-md mr-4" />
        <div className="flex-grow min-w-0 mr-4">
          <div className="flex items-baseline justify-between">
            <h4 className="text-yellow-400 font-semibold truncate mr-2 font-heading" title={song.title}>
              {song.title}
            </h4>
          </div>
          <p className="text-sm text-gray-400 truncate" title={song.artist}>
            {song.artist}
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center text-sm">
            <span className="text-green-500 mr-1">↑{song.upvotes}</span>
            <span className="text-red-500">↓{song.downvotes}</span>
          </div>
          <button onClick={handlePreview} className="w-8 h-8 flex-shrink-0 flex items-center justify-center text-yellow-500 hover:text-yellow-400 focus:outline-none">
            <PlayCircle size={20} />
          </button>
          <button onClick={handleDownload} className="w-8 h-8 flex-shrink-0 flex items-center justify-center text-green-500 hover:text-green-400 focus:outline-none">
            <Download size={20} />
          </button>
          <button className="w-8 h-8 flex-shrink-0 flex items-center justify-center text-gray-400 hover:text-white focus:outline-none">
            <MoreVertical size={20} />
          </button>
        </div>
      </div>
    </Link>
  );
};

export default SongRow;