import React, { useState } from 'react';
import { Music, Upload, X } from 'lucide-react';

const SongFileUpload = ({ onFileChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Song file selected:', file.name);
      setSelectedFile(file);
      onFileChange(file);
    }
  };

  const removeSongFile = () => {
    setSelectedFile(null);
    onFileChange(null);
  };

  return (
    <div>
      <label htmlFor="songFile" className="block text-sm font-medium text-gray-300 mb-2">
        Song File
      </label>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md relative">
        <div className="space-y-1 text-center">
          {selectedFile ? (
            <>
              <div className="flex items-center justify-center">
                <Music className="h-12 w-12 text-indigo-400" />
                <span className="ml-2 text-gray-300">{selectedFile.name}</span>
              </div>
              <button
                type="button"
                onClick={removeSongFile}
                className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <X className="h-4 w-4 mr-1" />
                Remove Song
              </button>
            </>
          ) : (
            <>
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-400">
                <label
                  htmlFor="songFile"
                  className="relative cursor-pointer bg-gray-700 rounded-md font-medium text-indigo-400 hover:text-indigo-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="songFile"
                    name="songFile"
                    type="file"
                    className="sr-only"
                    onChange={handleFileChange}
                    accept="audio/*"
                    required
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-400">MP3, WAV up to 10MB</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SongFileUpload;