import { S3Client } from "@aws-sdk/client-s3";

const ACCOUNT_ID = process.env.REACT_APP_R2_ACCOUNT_ID;
const ACCESS_KEY_ID = process.env.REACT_APP_R2_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_R2_SECRET_ACCESS_KEY;

if (!ACCOUNT_ID || !ACCESS_KEY_ID || !SECRET_ACCESS_KEY) {
  console.error('R2 configuration is incomplete. Please check your environment variables.');
}

export const r2Client = new S3Client({
  region: "auto",
  endpoint: `https://${ACCOUNT_ID}.r2.cloudflarestorage.com`,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export const BUCKET_NAME = 'songlib'; // Make sure this matches your bucket name

console.log('R2 Configuration:', {
  endpoint: r2Client.config.endpoint,
  bucketName: BUCKET_NAME
});