import { db } from '../firebase';
import { collection, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';

export const updateSongCount = async () => {
  console.log('Updating song count');
  try {
    const songsCollection = collection(db, 'songs');
    const querySnapshot = await getDocs(songsCollection);
    const count = querySnapshot.size;

    const statsRef = doc(db, 'stats', 'songCount');
    await setDoc(statsRef, { count: count }, { merge: true });

    console.log('Song count updated successfully:', count);
    return count;
  } catch (error) {
    console.error('Error in updateSongCount:', error);
    throw error;
  }
};

export const getSongCount = async () => {
  console.log('Fetching song count');
  try {
    if (!db) {
      throw new Error('Firestore not initialized');
    }
    const statsRef = doc(db, 'stats', 'songCount');
    const docSnapshot = await getDoc(statsRef);
    
    if (docSnapshot.exists()) {
      const count = docSnapshot.data().count;
      console.log('Song count fetched successfully:', count);
      return count;
    } else {
      console.log('No song count document found, updating count');
      return await updateSongCount();
    }
  } catch (error) {
    console.error('Error in getSongCount:', error);
    throw error;
  }
};

export const manualUpdateSongCount = async () => {
  try {
    const count = await updateSongCount();
    console.log('Song count manually updated:', count);
    return count;
  } catch (error) {
    console.error('Error in manualUpdateSongCount:', error);
    throw error;
  }
};