import React, { useState, useRef, useEffect } from 'react';
import PageLayout from './layout/PageLayout';
import { ChevronDown, ChevronUp } from 'lucide-react';

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className="border-b border-gray-700">
      <button
        className="flex justify-between items-center w-full py-6 px-8 text-left focus:outline-none"
        onClick={onClick}
      >
        <span className="text-yellow-400 hover:text-yellow-300 transition-colors text-lg font-semibold font-heading">{question}</span>
        {isOpen ? (
          <ChevronUp className="flex-shrink-0 text-yellow-400 transition-transform duration-300 w-6 h-6" />
        ) : (
          <ChevronDown className="flex-shrink-0 text-yellow-400 transition-transform duration-300 w-6 h-6" />
        )}
      </button>
      <div 
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{ maxHeight: `${height}px` }}
      >
        <div ref={contentRef} className="px-8 pb-6 text-gray-300">
          <p className="text-base leading-relaxed">{answer}</p>
        </div>
      </div>
    </div>
  );
};

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "Who creates the AI-generated songs on MeloDroid?",
      answer: "Our small, dedicated team creates all the songs offered on MeloDroid. We use AI tools like Suno and Udio to generate unique tracks, which we then curate and make available for download."
    },
    {
      question: "Are the AI-generated songs really free to download?",
      answer: "Yes, all songs on MeloDroid are completely free to download. Our mission is to make AI-generated music accessible to everyone, without any cost barriers."
    },
    {
      question: "Can I use MeloDroid's songs in my own projects?",
      answer: "Absolutely! All songs on MeloDroid are available for both personal and commercial use. We just ask that you credit MeloDroid when using our songs in public or commercial works."
    },
    {
      question: "What genres of music do you create?",
      answer: "We create music across a wide range of genres, including pop, rock, electronic, classical, and more. Our team strives to provide diverse options to suit various tastes and needs."
    },
    {
      question: "How often do you add new songs to MeloDroid?",
      answer: "We regularly create and add new AI-generated songs to our platform. Our team is constantly working on fresh tracks to expand our library and provide new options for our users."
    },
    {
      question: "How do you ensure the quality of your AI-generated songs?",
      answer: "Our team personally oversees the creation of each song using AI tools. We apply our musical expertise to refine and select only the best outputs, ensuring high-quality tracks for our users."
    }
  ];
  
  return (
    <PageLayout>
      <main className="bg-gray-900 flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-4xl font-bold text-white mb-8 font-heading">Frequently Asked Questions</h1>
          <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
            {faqs.map((faq, index) => (
              <AccordionItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={openIndex === index}
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              />
            ))}
          </div>
        </div>
      </main>
    </PageLayout>
  );
};

export default FAQPage;