import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl as awsGetSignedUrl } from "@aws-sdk/s3-request-presigner";

const s3Client = new S3Client({
  region: "auto",
  endpoint: `https://${process.env.REACT_APP_R2_ACCOUNT_ID}.r2.cloudflarestorage.com`,
  credentials: {
    accessKeyId: process.env.REACT_APP_R2_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_R2_SECRET_ACCESS_KEY,
  },
});

export const getPresignedUrl = async (path) => {
  const command = new PutObjectCommand({
    Bucket: process.env.REACT_APP_R2_BUCKET_NAME,
    Key: path,
  });

  const signedUrl = await awsGetSignedUrl(s3Client, command, { expiresIn: 3600 });
  return signedUrl;
};
