import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PageLayout from '../layout/PageLayout';
import SongHeader from './SongHeader';
import SongInfo from './SongInfo';
import AudioPlayer from './AudioPlayer';
import CommentSection from './CommentSection';
import SidebarContentSingleSongView from './SidebarContentSingleSongView';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { downloadSong } from '../../utils/downloadSong';

const SongDetailPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [song, setSong] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSong = async () => {
      if (!slug) {
        setError('No song slug provided');
        setLoading(false);
        return;
      }

      try {
        const songsRef = collection(db, 'songs');
        const q = query(songsRef, where('slug', '==', slug));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const songDoc = querySnapshot.docs[0];
          setSong({ id: songDoc.id, ...songDoc.data() });
        } else {
          setError('Song not found');
        }
      } catch (error) {
        console.error('Error fetching song:', error);
        setError('Error fetching song details');
      } finally {
        setLoading(false);
      }
    };

    fetchSong();
  }, [slug]);

  const handleDownload = async () => {
    if (song) {
      try {
        await downloadSong(song);
      } catch (error) {
        console.error("Error downloading song:", error);
        alert("Failed to download the song. Please try again later.");
      }
    }
  };

  if (loading) {
    return (
      <PageLayout>
        <div className="flex items-center justify-center h-screen bg-gray-900">
          <div className="text-white text-center">Loading song...</div>
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="flex items-center justify-center h-screen bg-gray-900">
          <div className="text-white text-center">
            <p>{error}</p>
            <button 
              onClick={() => navigate('/library')} 
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Back to Library
            </button>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (!song) {
    return (
      <PageLayout>
        <div className="flex items-center justify-center h-screen bg-gray-900">
          <div className="text-white text-center">Song not found</div>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="bg-gray-900">
        <SongHeader title={song.title} />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col lg:flex-row lg:space-x-8">
            <div className="lg:w-2/3">
              <SongInfo song={song} onDownload={handleDownload} />
              <AudioPlayer song={song} />
              <CommentSection comments={song.comments} />
            </div>
            <div className="lg:w-1/3 mt-6 lg:mt-0">
              <SidebarContentSingleSongView song={song} onDownload={handleDownload} />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default SongDetailPage;