import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import PageLayout from './layout/PageLayout';
import Header from './layout/Header';
import { auth, db } from '../firebase';
import { updateProfile } from 'firebase/auth';
import { doc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { debounce } from 'lodash';

const UserSettingsPage = () => {
  const user = auth.currentUser;
  const [username, setUsername] = useState('');
  const [originalUsername, setOriginalUsername] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);

  const usernameRef = useRef(username);
  const originalUsernameRef = useRef(originalUsername);

  useEffect(() => {
    usernameRef.current = username;
    originalUsernameRef.current = originalUsername;
  }, [username, originalUsername]);

  useEffect(() => {
    const fetchUsername = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const fetchedUsername = userDoc.data().username || '';
          setUsername(fetchedUsername);
          setOriginalUsername(fetchedUsername);
        }
      }
    };
    fetchUsername();
  }, [user]);

  const debouncedCheck = useRef(
    debounce(async (username) => {
      if (username === originalUsernameRef.current) {
        setIsChecking(false);
        setIsAvailable(null);
        setError(null);
        return;
      }
      
      setIsChecking(true);
      const q = query(collection(db, "users"), where("username", "==", username));
      const querySnapshot = await getDocs(q);
      setIsChecking(false);
      
      if (!querySnapshot.empty) {
        setIsAvailable(false);
        setError("This username is already taken.");
      } else {
        setIsAvailable(true);
        setError(null);
      }
    }, 500)
  ).current;

  const checkUsernameUniqueness = useCallback(() => {
    debouncedCheck(usernameRef.current);
  }, [debouncedCheck]);

  useEffect(() => {
    if (username && username !== originalUsername) {
      checkUsernameUniqueness();
    } else {
      setIsAvailable(null);
      setError(null);
    }
  }, [username, checkUsernameUniqueness, originalUsername]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    if (isChecking) {
      setError("Please wait for the username check to complete.");
      return;
    }

    if (!isAvailable && username !== originalUsername) {
      setError("Please choose an available username.");
      return;
    }

    try {
      await updateProfile(user, { displayName: username });
      await updateDoc(doc(db, "users", user.uid), { username: username });
      setSuccess(true);
      setOriginalUsername(username);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <PageLayout>
      <Header />
      <main className="bg-gray-900 min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-extrabold text-white font-heading">
              User Settings
            </h2>
            <Link
              to="/profile"
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Back to Profile
            </Link>
          </div>
          
          <div className="bg-gray-800 shadow overflow-hidden sm:rounded-lg">
            <form onSubmit={handleSubmit} className="px-4 py-5 sm:p-6">
              <div className="mb-4 max-w-md">
                <label htmlFor="username" className="block text-sm font-medium text-gray-300 mb-2 font-heading">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-600 rounded-md bg-gray-700 text-white px-4 py-2"
                />
                {isChecking && <p className="text-yellow-500 text-sm mt-1">Checking username availability...</p>}
                {!isChecking && isAvailable === true && username !== originalUsername && (
                  <p className="text-green-500 text-sm mt-1">Username available!</p>
                )}
                {!isChecking && isAvailable === false && (
                  <p className="text-red-500 text-sm mt-1">Username not available!</p>
                )}
              </div>
              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
              {success && <p className="text-green-500 text-sm mb-4">Profile updated successfully!</p>}
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={isChecking || (isAvailable === false && username !== originalUsername)}
              >
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </main>
    </PageLayout>
  );
};

export default UserSettingsPage;