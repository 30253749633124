import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bell, User, ChevronDown, Menu, X } from 'lucide-react';
import UserProfileForm from '../UserProfileForm';
import AuthModal from '../auth/AuthModal';
import { auth, db } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import logo from '../../assets/logo.webp';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const profileRef = useRef(null);

  const toggleProfileForm = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeDoc = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setUsername(docSnapshot.data().username || 'User');
          }
        });
        return () => unsubscribeDoc();
      } else {
        setUsername('');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <nav className="bg-gray-900 text-gray-300 py-6 px-4">
      <div className="max-w-7xl mx-auto flex items-center">
        <Link to="/" className="mr-8">
          <img src={logo} alt="SongDownload Logo" className="h-8" />
        </Link>
        <div className="flex-grow">
          <button 
            className="sm:hidden"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
              console.log("Menu open:", !isMenuOpen);
            }}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <div className={`${isMenuOpen ? 'block' : 'hidden'} sm:flex flex-col sm:flex-row items-center sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 absolute sm:relative top-20 sm:top-0 left-0 sm:left-auto bg-gray-900 sm:bg-transparent w-full sm:w-auto h-[calc(100vh-5rem)] sm:h-auto p-4 sm:p-0 z-50`}>
            <Link to="/faq" className="hover:text-white text-lg w-full text-center sm:w-auto block sm:inline-block">FAQ</Link>
            <Link to="/library" className="hover:text-white text-lg w-full text-center sm:w-auto block sm:inline-block">Song-Bibliothek</Link>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div 
            ref={profileRef}
            className="relative"
          >
            <div 
              className="hidden sm:flex items-center space-x-2 cursor-pointer"
              onClick={toggleProfileForm}
            >
              <User size={20} />
              <span>{auth.currentUser ? username : 'Anmelden'}</span>
              <ChevronDown size={16} />
            </div>
            <UserProfileForm 
              isOpen={isProfileOpen} 
              onClose={() => setIsProfileOpen(false)} 
              anchorRef={profileRef}
              openAuthModal={() => setIsAuthModalOpen(true)}
            />
          </div>
          <Bell size={20} className="cursor-pointer" />
          <User size={20} className="cursor-pointer sm:hidden" onClick={toggleProfileForm} />
        </div>
      </div>
      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
      />
    </nav>
  );
};

export default Navigation;