import React, { useState, useEffect } from 'react';
import { ThumbsUp, ThumbsDown, Clock, Music, User, Calendar, Download } from 'lucide-react';
import { downloadSong } from '../../utils/downloadSong';
import { voteSong } from '../../utils/songManagement';
import { auth } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const SongInfo = ({ song }) => {
  const [votes, setVotes] = useState({ upvotes: song.upvotes || 0, downvotes: song.downvotes || 0 });
  const [userVote, setUserVote] = useState(null);
  const [error, setError] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const checkUserVote = async () => {
      if (user) {
        const userVotesRef = doc(db, 'userVotes', user.uid);
        const userVotesDoc = await getDoc(userVotesRef);
        if (userVotesDoc.exists()) {
          const userVotes = userVotesDoc.data();
          setUserVote(userVotes[song.id]);
        }
      }
    };
    checkUserVote();
  }, [song.id, user]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDownload = async () => {
    if (song) {
      try {
        await downloadSong(song);
      } catch (error) {
        console.error("Error downloading song:", error);
        alert("Failed to download the song. Please try again later.");
      }
    }
  };

  const handleVote = async (voteType) => {
    setError(null);
    if (!user) {
      setError('You must be logged in to vote');
      return;
    }
    if (userVote) {
      setError('You have already voted on this song');
      return;
    }
    try {
      await voteSong(song.id, voteType, user.uid);
      setVotes(prev => ({
        ...prev,
        [voteType === 'upvote' ? 'upvotes' : 'downvotes']: prev[voteType === 'upvote' ? 'upvotes' : 'downvotes'] + 1
      }));
      setUserVote(voteType);
    } catch (error) {
      console.error(`Error ${voteType}ing song:`, error);
      setError(`Failed to ${voteType} the song. ${error.message}`);
    }
  };

  return (
    <div className="pb-8"> {/* Added padding-bottom */}
      <div className="mb-6">
        <p className="text-2xl text-gray-300">{song.artist}</p>
      </div>

      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          <button 
            onClick={() => handleVote('upvote')} 
            className={`flex items-center px-3 py-1 ${userVote === 'upvote' ? 'bg-green-600' : 'bg-green-500'} text-white rounded-full hover:bg-green-600 transition-colors ${(!user || userVote) ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!user || userVote}
          >
            <ThumbsUp size={16} className="mr-1" /> {votes.upvotes}
          </button>
          <button 
            onClick={() => handleVote('downvote')} 
            className={`flex items-center px-3 py-1 ${userVote === 'downvote' ? 'bg-red-600' : 'bg-red-500'} text-white rounded-full hover:bg-red-600 transition-colors ${(!user || userVote) ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!user || userVote}
          >
            <ThumbsDown size={16} className="mr-1" /> {votes.downvotes}
          </button>
        </div>
        <button onClick={handleDownload} className="flex items-center px-4 py-2 bg-yellow-500 text-gray-900 rounded-full hover:bg-yellow-600 transition-colors">
          <Download size={16} className="mr-2" /> Download
        </button>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {!user && <p className="text-yellow-500 mt-2">Log in to vote on this song</p>}
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div className="flex items-center">
          <Clock size={20} className="mr-3 text-gray-400" />
          <p className="text-gray-300 text-lg">Duration: {formatTime(song.duration)}</p>
        </div>
        <div className="flex items-center">
          <Music size={20} className="mr-3 text-gray-400" />
          <p className="text-gray-300 text-lg">Genre: {song.genreName || 'Unknown'}</p>
        </div>
        <div className="flex items-center">
          <User size={20} className="mr-3 text-gray-400" />
          <p className="text-gray-300 text-lg">Uploaded by: {song.uploaderUsername}</p>
        </div>
        <div className="flex items-center">
          <Calendar size={20} className="mr-3 text-gray-400" />
          <p className="text-gray-300 text-lg">Uploaded at: {song.uploadedAt ? new Date(song.uploadedAt.seconds * 1000).toLocaleDateString() : 'N/A'}</p>
        </div>
      </div>
    </div>
  );
};

export default SongInfo;