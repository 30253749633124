// src/components/LoginForm.js
import React, { useState } from 'react';
import PasswordInput from './PasswordInput';

const LoginForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <input 
          type="email" 
          placeholder="Email" 
          className="w-full p-2 text-white bg-gray-700 rounded"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <PasswordInput
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button type="submit" className="w-full p-2 mb-4 text-white bg-blue-500 rounded hover:bg-blue-600">
        Login
      </button>
    </form>
  );
};

export default LoginForm;