import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy, limit, where } from 'firebase/firestore';
import { db } from '../../firebase';

const useSongLibrary = () => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('table');
  const [songs, setSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState({
    search: '',
    submittedDate: 'Any',
    genre: { value: 'Any', label: 'Any Genre' },
    instrumentalsOnly: false,
    tempo: { value: 'Any', label: 'Any Tempo' },
    duration: { value: 'Any', label: 'Any Duration' },
  });

  const handleSongClick = (song) => {
    navigate(`/song/${song.slug}`);
  };

  const fetchSongs = useCallback(async () => {
    setIsLoading(true);
    try {
      let songsRef = collection(db, 'songs');
      let constraints = [orderBy('uploadedAt', 'desc'), limit(50)];

      if (filterCriteria.genre && filterCriteria.genre.value !== 'Any') {
        constraints.push(where('genreName', '==', filterCriteria.genre.label));
      }

      if (filterCriteria.submittedDate !== 'Any') {
        const date = new Date();
        switch (filterCriteria.submittedDate) {
          case 'LastWeek':
            date.setDate(date.getDate() - 7);
            break;
          case 'LastMonth':
            date.setMonth(date.getMonth() - 1);
            break;
          case 'LastYear':
            date.setFullYear(date.getFullYear() - 1);
            break;
          default:
            break;
        }
        constraints.push(where('uploadedAt', '>=', date));
      }

      if (filterCriteria.instrumentalsOnly) {
        constraints.push(where('isInstrumental', '==', true));
      }

      const q = query(songsRef, ...constraints);
      const querySnapshot = await getDocs(q);
      let fetchedSongs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Apply client-side filtering for tempo and duration
      if (filterCriteria.tempo && filterCriteria.tempo.value !== 'Any') {
        fetchedSongs = fetchedSongs.filter(song => {
          const bpm = song.bpm || 0;
          switch (filterCriteria.tempo.value) {
            case 'Slow':
              return bpm > 0 && bpm <= 90;
            case 'Medium':
              return bpm > 90 && bpm <= 140;
            case 'Fast':
              return bpm > 140;
            default:
              return true;
          }
        });
      }

      if (filterCriteria.duration && filterCriteria.duration.value !== 'Any') {
        fetchedSongs = fetchedSongs.filter(song => {
          const duration = song.duration || 0;
          switch (filterCriteria.duration.value) {
            case 'Short':
              return duration > 0 && duration <= 120; // 0-2 minutes
            case 'Medium':
              return duration > 120 && duration <= 240; // 2-4 minutes
            case 'Long':
              return duration > 240; // 4+ minutes
            default:
              return true;
          }
        });
      }

      if (filterCriteria.search) {
        const searchLower = filterCriteria.search.toLowerCase();
        fetchedSongs = fetchedSongs.filter(song =>
          song.title.toLowerCase().includes(searchLower) ||
          song.artist.toLowerCase().includes(searchLower) ||
          song.uploaderUsername.toLowerCase().includes(searchLower)
        );
      }

      setSongs(fetchedSongs);
    } catch (error) {
      console.error("Error fetching songs:", error);
    } finally {
      setIsLoading(false);
    }
  }, [filterCriteria]);

  useEffect(() => {
    fetchSongs();
  }, [fetchSongs]);

  const handleApplyFilter = () => {
    fetchSongs();
  };

  const playSong = (event, song) => {
    event.stopPropagation();
    setCurrentlyPlaying(song);
  };

  const pauseSong = () => {
    setCurrentlyPlaying(null);
  };

  return {
    viewMode,
    setViewMode,
    songs,
    isLoading,
    currentlyPlaying,
    filterCriteria,
    setFilterCriteria,
    handleSongClick,
    playSong,
    pauseSong,
    handleApplyFilter,
  };
};

export default useSongLibrary;