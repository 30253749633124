import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const useFetchSongs = () => {
  const [topRatedSongs, setTopRatedSongs] = useState([]);
  const [latestSongs, setLatestSongs] = useState([]);
  const [lastDownloadedSongs, setLastDownloadedSongs] = useState([]);

  useEffect(() => {
    const fetchSongs = async () => {
      // Fetch Top Rated Songs
      const topRatedQuery = query(
        collection(db, 'songs'),
        orderBy('rating', 'desc'),
        limit(5)
      );
      const topRatedSnapshot = await getDocs(topRatedQuery);
      setTopRatedSongs(topRatedSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch Latest Songs
      const latestQuery = query(
        collection(db, 'songs'),
        orderBy('uploadedAt', 'desc'),
        limit(5)
      );
      const latestSnapshot = await getDocs(latestQuery);
      setLatestSongs(latestSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch Last Downloaded Songs
      const lastDownloadedQuery = query(
        collection(db, 'songs'),
        orderBy('lastDownloadedAt', 'desc'),
        limit(5)
      );
      const lastDownloadedSnapshot = await getDocs(lastDownloadedQuery);
      setLastDownloadedSongs(lastDownloadedSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchSongs();
  }, []);

  return { topRatedSongs, latestSongs, lastDownloadedSongs };
};

export default useFetchSongs;