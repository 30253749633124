import React, { createContext, useState, useContext, useRef, useCallback } from 'react';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [currentSong, setCurrentSong] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [volume, setVolume] = useState(0.2);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const audioRef = useRef(new Audio());

  const playSong = useCallback((song) => {
    return new Promise((resolve, reject) => {
      console.log('playSong called with:', song);
      setIsLoading(true);

      const playAudio = () => {
        console.log('Attempting to play audio');
        audioRef.current.volume = volume;
        audioRef.current.play()
          .then(() => {
            console.log('Audio played successfully');
            setCurrentSong(song);
            setIsLoading(false);
            setIsModalOpen(true);
            resolve();
          })
          .catch(error => {
            console.error('Error playing audio:', error);
            setIsLoading(false);
            reject(error);
          });
      };

      if (currentSong && currentSong.id === song.id) {
        if (audioRef.current.paused) {
          console.log('Resuming current song');
          playAudio();
        } else {
          console.log('Song is already playing');
          setIsLoading(false);
          resolve();
        }
      } else {
        console.log('Loading new song');
        audioRef.current.src = song.songFileUrl;
        audioRef.current.load();
        audioRef.current.oncanplaythrough = playAudio;
      }
    });
  }, [currentSong, volume]);

  const pauseSong = useCallback(() => {
    console.log('Pausing song');
    audioRef.current.pause();
  }, []);

  const changeVolume = useCallback((newVolume) => {
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    pauseSong();
  }, [pauseSong]);

  const value = {
    currentSong,
    playSong,
    pauseSong,
    audioRef,
    isLoading,
    volume,
    changeVolume,
    isModalOpen,
    closeModal,
  };

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};

export default AudioContext;