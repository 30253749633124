import React, { useState, useRef, useEffect } from 'react';
import { X } from 'lucide-react';

const NewsletterSignupModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [honeypot, setHoneypot] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const modalRef = useRef();
  const formOpenTime = useRef(Date.now());

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      formOpenTime.current = Date.now(); // Reset the form open time when the modal opens
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Honeypot check
    if (honeypot) {
      console.log('Bot detected via honeypot');
      setError('An error occurred. Please try again.');
      return;
    }

    // Time check
    if (Date.now() - formOpenTime.current < 3000) { // 3 seconds threshold
      console.log('Submission too quick, likely a bot');
      setError('Please wait a moment before submitting.');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      // TODO: Replace this with actual Brevo integration
      // Simulate an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      console.log('Email submitted:', email);
      setSuccess(true);
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div ref={modalRef} className="bg-gray-800 rounded-lg p-6 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold text-white mb-4 font-heading">Subscribe to Our Newsletter</h2>
        {success ? (
          <div className="text-green-400 mb-4">
            Thank you for subscribing! Check your email for confirmation.
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <p className="text-gray-300 mb-4">
              Sign up to receive exclusive updates and be among the first to learn how you can win a free subscription!
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            {/* Honeypot field */}
            <input
              type="text"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
              style={{ display: 'none' }}
              tabIndex="-1"
              autoComplete="off"
            />
            {error && <p className="text-red-400 mb-4">{error}</p>}
            <button
              type="submit"
              className="w-full bg-yellow-500 text-black font-bold py-2 px-4 rounded hover:bg-yellow-400 transition-colors"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Subscribe'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default NewsletterSignupModal;